import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Features from '../components/Features';
// import Integrations from '../components/Integrations';
import Solutions from '../components/Solutions';
// import Testimonials from '../components/Testimonials';
import CallToAction from '../components/CallToAction';
import BlogUpdates from '../components/BlogUpdates';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <div>
            <Helmet>
                <title>Samey AI - Your AI Work Assistant for Enterprises</title>
                <meta name="description" content="Samey AI is your AI work assistant for enterprises, helping you streamline tasks and enhance productivity with advanced artificial intelligence solutions." />
                <meta name="keywords" content="Samey AI, AI work assistant, enterprise AI solutions, productivity tools, artificial intelligence, business automation" />
                <meta name="author" content="Samey AI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <HeroSection />
            <Features />
            {/* <Integrations /> */}
            <Solutions /><br></br><br></br>
            {/* <Testimonials /> */}
            <CallToAction />
            <BlogUpdates />
            <Footer />
        </div>
    );
};

export default Home;
