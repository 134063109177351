import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Careers from './pages/Careers';
import Conectors from './pages/Connectors'
import Privacy from './pages/Privacy'
import Contact from './pages/Contact';
import BlogPost3 from './pages/BlogPost3';
import BlogPost2 from './pages/BlogPost2';
import BlogPost1 from './pages/BlogPost1';
import Legal from './pages/Legal';
import Consulting from './pages/Consulting';
import Finance from './pages/Finance';
import Healthcare from './pages/Healthcare';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/careers" element={< Careers />} />
        <Route path="/blog/transforming-legal-practices-using-samey" element={<BlogPost3 />} />
        <Route path="/blog/how-samey-is-revolutionising-sme-operations" element={<BlogPost2 />} />
        <Route path="/blog/intoducing-samey-ai" element={<BlogPost1 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/connectors" element={<Conectors />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/healthcare" element={<Healthcare />} />

        

        {/* Add more routes here as needed */}
      </Routes>
    </Router>
  );
};

export default App;