import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/About.css';
import Header from '../components/Header'; 
import CallToAction from '../components/CallToAction'; 
import Footer from '../components/Footer';
import logo from '../assets/images/Frame 187.png'
import mondayLogo from '../assets/images/download.png'; 
import leapLogo from '../assets/images/leap.png';
import xeroLogo from '../assets/images/Xero.png';
import outlookLogo from '../assets/images/outlook.png';
import stripeLogo from '../assets/images/stripe.png';
import powerbiLogo from '../assets/images/powerbi.png';
import botpressLogo from '../assets/images/botpress.png';
import teamMemberImage from '../assets/images/Frame 187.png'

const About = () => {
    return (
        <div>
            <Helmet>
                <title>SameyAI - About Us</title>
                <meta name="description" content="Learn more about SameyAI, an AI-powered work assistant designed to revolutionize business operations. Discover our mission, vision, and meet the team behind the innovation." />
                <meta name="keywords" content="SameyAI, about us, AI-powered work assistant, business automation, enterprise search engine, business efficiency, AI solutions" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header /> {/* Include the header component */}
            <section className="about-section">
                <div className="about-header">
                    <span className="about-tag">ABOUT US</span>
                </div>
                <h1>Revolutionising The Way Businesses Manage Their Data And Workflow</h1>
                <img src={logo} alt="Team" className="about-image" />
                <div className="about-content">
                    <h2>Who we are</h2>
                    <p>Samey is a cutting-edge AI-powered work assistant and enterprise search engine designed to transform business operations. Founded by experts in business automation and AI development, we focus on providing a scalable and user-centric solution that integrates seamlessly with existing systems. Our goal is to empower businesses to achieve greater productivity and operational excellence.</p>
                </div>
                <div className="about-content">
                    <h2>Our Mission</h2>
                    <p>We aim to revolutionise business management by providing a robust AI-powered personal assistant. This assistant integrates effortlessly with existing systems, automates repetitive tasks, and delivers data-driven insights to enhance decision-making and operational efficiency.</p>
                </div>
                <div className="about-content">
                    <h2>Vision</h2>
                    <p>To empower businesses with intelligent, seamless, and adaptive AI solutions that transform data into actionable insights, automate routine tasks, and optimise workflows, driving efficiency, innovation, and strategic growth.</p>
                </div>
                <div className="trusted-companies-section">
                    <h2>Integrations</h2>
                    <div className="logos">
                        <img src={mondayLogo} alt="Monday.com" className="company-logo" />
                        <img src={leapLogo} alt="Leap" className="company-logo" />
                        <img src={xeroLogo} alt="Xero" className="company-logo" />
                        <img src={outlookLogo} alt="Outlook" className="company-logo" />
                        <img src={stripeLogo} alt="Stripe" className="company-logo" />
                        <img src={powerbiLogo} alt="PowerBI" className="company-logo" />
                        <img src={botpressLogo} alt="Botpress" className="company-logo" />
                    </div>
                </div>
                {/*
                <div className="team-section">
                    <h2>Meet Our Team</h2>
                    <div className="team-members">
                        <div className="team-member">
                            <p className="position">Co-founder and Chief Executive Officer</p>
                            <h3>Mir Mohamed Abbas</h3>
                        </div>
                        <div className="team-member">
                            <p className="position">Co-founder and Chief Technology Officer</p>
                            <h3>Yash Chopda</h3>
                        </div>
                        <div className="team-member">
                            <p className="position">Full Stack Developer & Data Scientist</p>
                            <h3>Aaron Cherian</h3>
                        </div>
                        <div className="team-member">
                            <p className="position">Cloud Solutions Architect</p>
                            <h3>Alan John</h3>
                        </div>
                        <div className="team-member">
                            <p className="position">UI/UX Designer</p>
                            <h3>Oluchi Gift</h3>
                        </div>
                    </div>
                </div>
                */}
            </section>
            <CallToAction /> 
            <Footer />
        </div>
    );
}

export default About;
