import React from 'react';
import '../styles/Solutions.css';
import { Link } from 'react-router-dom'; // Import Link for navigation

const Solutions = () => {
    return (
        <section className="solutions-section">
            <div className="solutions-header">
                <span className="solutions-tag">SOLUTIONS</span>
                <h2>What problems are we solving?</h2>
            </div>
            <div className="solutions">
                <div className="solution">
                    <h3>Legal</h3>
                    <p>Transform Legal Practice with AI-Powered Precision.</p>
                    <Link to="/legal" className="learn-more">Learn More</Link>
                </div>
                <div className="solution">
                    <h3>Finance</h3>
                    <p>Smart Solution For Smarter Financial Decisions.</p>
                    <Link to="/finance" className="learn-more">Learn More</Link>
                </div>
                <div className="solution">
                    <h3>Consulting</h3>
                    <p>Unlock Consulting Potential With AI Brilliance.</p>
                    <Link to="/consulting" className="learn-more">Learn More</Link>
                </div>
                <div className="solution">
                    <h3>Healthcare</h3>
                    <p>Empower Healthcare With AI-Powered Solutions.</p>
                    <Link to="/healthcare" className="learn-more">Learn More</Link>
                </div>
            </div>
        </section>
    );
}

export default Solutions;
