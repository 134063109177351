import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Connectors.css';
import Header from '../components/Header'; 
import Footer from '../components/Footer'; 
import CallToAction from '../components/CallToAction';
import connectorsImage from '../assets/images/Connectors header image.png';  

import slackIcon from '../assets/images/slack-svgrepo-com 1.png';  
import jiraIcon from '../assets/images/jira-svgrepo-com 1.png';  
import googleDriveIcon from '../assets/images/Google_Drive_logo.png';  
import zendeskIcon from '../assets/images/zendesk-svgrepo-com 1.png';  
import gmailIcon from '../assets/images/Gmail_Logo.png';  
import sharepointIcon from '../assets/images/sharepoint.png';  
import leapIcon from '../assets/images/leap.png';  
import outlookIcon from '../assets/images/outlook.png';  
import teamsIcon from '../assets/images/teams.png';  
import mondayIcon from '../assets/images/monday-icon-svgrepo-com 1.png';  
import googleMeetIcon from '../assets/images/googlemeet.png';  
import xeroIcon from '../assets/images/xero-svgrepo-com 1.png';  
import salesforceIcon from '../assets/images/salesforce.png';
import stripeIcon from '../assets/images/stripe.png';

const connectors = [
    { name: 'LEAP', description: 'Legal Case Management', icon: leapIcon },
    { name: 'Outlook', description: 'Email and Calendar', icon: outlookIcon },
    { name: 'Teams', description: 'Collaboration', icon: teamsIcon },
    { name: 'Monday', description: 'Project Management', icon: mondayIcon },
    { name: 'Google Meet', description: 'Video Conferencing', icon: googleMeetIcon },
    { name: 'Xero', description: 'Financial Management', icon: xeroIcon },
    { name: 'Slack', description: 'Team Communication', icon: slackIcon },
    { name: 'Jira', description: 'Project Tracking', icon: jiraIcon },
    { name: 'Google Drive', description: 'Cloud Storage', icon: googleDriveIcon },
    { name: 'Zendesk', description: 'Customer Support', icon: zendeskIcon },
    { name: 'Gmail', description: 'Email', icon: gmailIcon },
    { name: 'SharePoint', description: 'Document Management', icon: sharepointIcon },
    { name: 'Salesforce', description: 'CRM', icon: salesforceIcon },
    { name: 'Stripe', description: 'Payment Processing', icon: stripeIcon },
];

const Connectors = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredConnectors, setFilteredConnectors] = useState(connectors);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = connectors.filter(connector =>
            connector.name.toLowerCase().includes(value) ||
            connector.description.toLowerCase().includes(value)
        );

        setFilteredConnectors(filtered);
    };

    return (
        <>
            <Helmet>
                <title>Connectors - Integrate with Samey AI</title>
                <meta name="description" content="Explore Samey AI's popular connectors and integrations that empower your enterprise workflows. Connect with tools like Slack, Google Drive, Jira, and more." />
                <meta name="keywords" content="Samey AI connectors, integrations, Slack, Google Drive, Jira, CRM, enterprise tools" />
                <meta name="author" content="Samey AI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="connectors-section">
                <div className="connectors-content">
                    <div className="connectors-text">
                        <span className="connectors-tag">CONNECTORS</span>
                        <h2>Samey empowers where you work</h2>
                    </div>
                    <div className="connectors-image">
                        <img src={connectorsImage} alt="Connectors" />
                    </div>
                </div>
            </div>
            <div className="popular-connectors-section">
                <h2>Popular Connectors</h2>
                <input 
                    type="text" 
                    placeholder="Search for connectors" 
                    className="search-bar" 
                    value={searchTerm} 
                    onChange={handleSearch} 
                />
                <div className="connectors-grid">
                    {filteredConnectors.length > 0 ? (
                        filteredConnectors.map((connector, index) => (
                            <div key={index} className="connector-card">
                                <img src={connector.icon} alt={connector.name} />
                                <h3>{connector.name}</h3>
                                <p>{connector.description}</p>
                            </div>
                        ))
                    ) : (
                        <p>No results found</p>
                    )}
                </div>
            </div>
            <CallToAction />
            <Footer />
        </>
    );
}

export default Connectors;
