import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/HeroSection.css';

const HeroSection = () => {
    return (
        <section className="hero-section">
            <div className="hero-content">
                <span className="hero-tagline">Enhancing workspaces for easy workflow</span>
                <h1 className="hero-title">Your AI Work Assistant for Enterprises</h1>
                <p className="hero-subtitle">Transforming data into actionable insights</p>
                <div className="hero-buttons">
                    <NavLink to="/contact">
                        <button className="hero-button primary">Request a Demo</button>
                    </NavLink>
                    <NavLink to="/contact">
                        <button className="hero-button secondary">Learn More</button>
                    </NavLink>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
