import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Finance.css';
import Header from '../components/Header';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import Icon1 from '../assets/images/security-check.png';
import Icon2 from '../assets/images/market-analysis.png';
import Icon3 from '../assets/images/conference.png';
import InfoImage1 from '../assets/images/Automated Client Communications.png';  
import InfoImage2 from '../assets/images/Investment Analysis.png';  
import InfoImage3 from '../assets/images/Regulatory Compliance.png';  

const Finance = () => {
    return (
        <>
            <Helmet>
                <title>SameyAI - Finance</title>
                <meta name="description" content="Empower your financial decisions with AI-driven solutions from SameyAI. Explore enhanced data security, automated reporting, investment analysis, and regulatory compliance tools." />
                <meta name="keywords" content="SameyAI, finance AI, financial technology, data security, financial analysis, automated reporting, investment analysis, regulatory compliance" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="finance-header">
                <div className="finance-header-content">
                    <span className="finance-tag">FINANCE</span>
                    <h1>Smart Solution For Smarter Financial Decisions</h1>
                </div>
            </div>
            <div className="finance-solutions-section">
                <h2>Solutions we provide</h2>
                <div className="solutions-grid">
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon1} alt="Enhanced Data Security" />
                        </div>
                        <h3>Enhanced Data Security</h3>
                        <p>Ensures that sensitive financial data is encrypted and access is strictly controlled based on user roles.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon2} alt="Analysing Financial Data" />
                        </div>
                        <h3>Analysing Financial Data</h3>
                        <p>Provides deep financial insights through advanced data analytics.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon3} alt="Automated Reporting" />
                        </div>
                        <h3>Automated Reporting</h3>
                        <p>Generate detailed investment performance reports, providing clients with transparent and up-to-date information about their portfolios.</p>
                    </div>
                </div>
            </div>
            <div className="information-retrieval-section">
                <div className="information-retrieval-content">
                    <div className="info-text">
                        <h2>Automated Client Communications</h2>
                        <p>The AI can schedule and manage client communications, ensuring timely follow-ups and personalized interactions.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage1} alt="Automated Client Communications" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="data-management-section">
                <div className="data-management-content">
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage2} alt="Investment Analysis" className="info-image" />
                        </div>
                    </div>
                    <div className="info-text">
                        <h2>Investment Analysis</h2>
                        <p>Offers data-driven investment analysis and recommendations.</p>
                    </div>
                </div>
            </div>
            <div className="matter-information-section">
                <div className="matter-information-content">
                    <div className="info-text">
                        <h2>Regulatory Compliance</h2>
                        <p>Ensures financial institutions comply with regulations through automated compliance checks.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage3} alt="Regulatory Compliance" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <CallToAction />
            <Footer />
        </>
    );
}

export default Finance;
