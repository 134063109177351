import React from 'react';
import '../styles/Features.css';
import taskIcon from '../assets/images/task-daily-01.png'; // Import your icons
import personalizedIcon from '../assets/images/user.png';
import searchIcon from '../assets/images/ai-search.png';



const Features = () => {
    return (
        <section className="features-section">
            <div className="features-header">
                <span className="features-tag">FEATURES</span>
                <h2>What is Samey AI doing differently?</h2>
            </div>
            <div className="features">
                <div className="feature">
                    <div className="feature-icon-container">
                        <img src={taskIcon} alt="Task Automation" className="feature-icon" />
                    </div>
                    <h3>Task Automation</h3>
                    <p>Samey AI helps in automating routine tasks as well as scheduling reminders and data entry.</p>
                </div>
                <div className="feature">
                    <div className="feature-icon-container">
                        <img src={personalizedIcon} alt="Personalised Assistance" className="feature-icon" />
                    </div>
                    <h3>Personalised Assistance</h3>
                    <p>Helps in providing customized support tailored to individual user needs.</p>
                </div>
                <div className="feature">
                    <div className="feature-icon-container">
                        <img src={searchIcon} alt="Advanced Search Capabilities" className="feature-icon" />
                    </div>
                    <h3>Advanced Search Capabilities</h3>
                    <p>Helps in powerful search functionalities across various data sources.</p>
                </div>
            </div>
        </section>
    );
}

export default Features;
