import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';
import arrow from '../assets/images/arrow-down-01-sharp.png'; 
import logo from '../assets/images/Logo.png';

const Header = () => {
    return (
        <header className="header">
            <div className="header-container">
                <div className="logo">
                    <NavLink to="/" exact>
                        <img src={logo} alt="Samey AI Logo" />
                    </NavLink>
                </div>
                <nav className="nav-menu">
                    <ul className="nav-links">
                        <li>
                            <NavLink to="/" className="nav-link" activeClassName="active" exact>
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link">
                                Features
                                <img src={arrow} alt="dropdown arrow" className="dropdown-arrow" />
                            </div>
                            <ul className="dropdown">
                                <li><NavLink to="/connectors" className="nav-link">Integrations</NavLink></li>
                            </ul>
                        </li>
                        {/* <li>
                            <NavLink to="/pricing" className="nav-link" activeClassName="active">
                                Pricing
                            </NavLink>
                        </li>*/}
                        <li className="nav-item">
                            <div className="nav-link">
                                Solutions
                                <img src={arrow} alt="dropdown arrow" className="dropdown-arrow" />
                            </div>
                            <ul className="dropdown">
                                <li><NavLink to="/legal" className="nav-link">Legal</NavLink></li>
                                <li><NavLink to="/finance" className="nav-link">Finance</NavLink></li>
                                <li><NavLink to="/healthcare" className="nav-link">Healthcare</NavLink></li>
                                <li><NavLink to="/consulting" className="nav-link">Consulting</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link">
                                Resources
                                <img src={arrow} alt="dropdown arrow" className="dropdown-arrow" />
                            </div>
                            <ul className="dropdown">
                                <li><NavLink to="/blog" className="nav-link">Blogs</NavLink></li>
                                <li><NavLink to="/privacy" className="nav-link">Privacy Policy</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link">
                                Company
                                <img src={arrow} alt="dropdown arrow" className="dropdown-arrow" />
                            </div>
                            <ul className="dropdown">
                                <li><NavLink to="/about" className="nav-link">About Us</NavLink></li>
                                <li><NavLink to="/careers" className="nav-link">Careers</NavLink></li>
                                <li><NavLink to="/contact" className="nav-link">Contact Us</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <NavLink to="/contact">
                    <button className="cta-button" style={{
                        backgroundColor: '#000',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        cursor: 'pointer'
                    }}>Contact Us</button>
                </NavLink>
            </div>
        </header>
    );
};

export default Header;
