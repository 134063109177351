import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Legal.css';
import Header from '../components/Header';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import Icon1 from '../assets/images/agreement-03.png';
import Icon2 from '../assets/images/calendar-03.png';
import Icon3 from '../assets/images/agreement-01 (1).png';
import InfoImage1 from '../assets/images/Information Retrieval Image.png';  
import InfoImage2 from '../assets/images/Data Mangement image.png';  
import InfoImage3 from '../assets/images/Matter Information.png';  

const Legal = () => {
    return (
        <>
            <Helmet>
                <title>SameyAI - Legal</title>
                <meta name="description" content="Transform your legal practice with AI-powered precision solutions from SameyAI. Explore automated legal document management, workflow automation, and case reporting." />
                <meta name="keywords" content="SameyAI, legal AI, legal technology, document management, legal workflows, case progress reporting, data management, legal information retrieval" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="legal-header">
                <div className="legal-header-content">
                    <span className="legal-tag">LEGAL</span>
                    <h1>Transform Legal Practice with AI-Powered Precision</h1>
                </div>
            </div>
            <div className="legal-solutions-section">
                <h2>Solutions we provide</h2>
                <div className="solutions-grid">
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon1} alt="Managing Legal Documents" />
                        </div>
                        <h3>Managing Legal Documents</h3>
                        <p>Automatically extracts, categorizes, and organizes legal documents, enhancing accessibility.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon2} alt="Automating Legal Workflows" />
                        </div>
                        <h3>Automating Legal Workflows</h3>
                        <p>Automates scheduling, reminders, and updates calendars for legal professionals.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon3} alt="Reporting Case Progress" />
                        </div>
                        <h3>Reporting Case Progress</h3>
                        <p>Compiles and generates detailed case progress reports automatically.</p>
                    </div>
                </div>
            </div>
            <div className="information-retrieval-section">
                <div className="information-retrieval-content">
                    <div className="info-text">
                        <h2>Information Retrieval</h2>
                        <p>Searches for specific matters in case management systems and past closed matters.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage1} alt="Information Retrieval" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="data-management-section">
                <div className="data-management-content">
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage2} alt="Data Management" className="info-image" />
                        </div>
                    </div>
                    <div className="info-text">
                        <h2>Data Management</h2>
                        <p>Manages vast amounts of unstructured legal data efficiently.</p>
                    </div>
                </div>
            </div>
            <div className="matter-information-section">
                <div className="matter-information-content">
                    <div className="info-text">
                        <h2>Matter Information</h2>
                        <p>Provides comprehensive details and updates on legal matters.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage3} alt="Matter Information" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <CallToAction />
            <Footer />
        </>
    );
}

export default Legal;
