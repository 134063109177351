import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Privacy.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>SameyAI - Privacy Policy</title>
                <meta name="description" content="Read SameyAI's Privacy Policy to understand how we collect, use, and protect your personal information when you use our services. Learn about your choices and how to contact us with any questions." />
                <meta name="keywords" content="SameyAI, privacy policy, data protection, personal information, user data, cookies, security, user privacy" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="privacy-policy-container">
                <h1>Privacy Policy</h1>
                <p className="last-updated">Last updated: 29 July 2024</p>
                <section>
                    <h2>Introduction</h2>
                    <p>
                        This Privacy Policy explains how we collect, use, and share information about you when you use our services. By accessing or using our services, you agree to the terms of this policy. If you do not agree, please do not use our services.
                    </p>
                </section>
                <section>
                    <h2>1. Information We Collect</h2>
                    <h3>Information you provide to us</h3>
                    <ul>
                        <li><strong>Account Information:</strong> When you create an account, we collect personal information such as your name, email address, and password.</li>
                        <li><strong>Communication Information:</strong> If you contact us directly, we may receive additional information about you such as your name, email address, phone number, and the content of your message.</li>
                    </ul>
                    <h3>Information we collect automatically</h3>
                    <ul>
                        <li><strong>Usage Data:</strong> We collect information about your interactions with our services, such as pages visited, time spent on pages, and other usage metrics.</li>
                        <li><strong>Device Information:</strong> We collect information about the device and internet connection you use to access our services, including the device's IP address, browser type, and operating system.</li>
                        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to collect information about your use of our services and to provide a better user experience.</li>
                    </ul>
                </section>
                <section>
                    <h2>2. How We Use the Information We Collect</h2>
                    <p>We use the information we collect for various purposes, including:</p>
                    <ul>
                        <li><strong>To provide and maintain our services:</strong> To operate, maintain, and provide features and functionality.</li>
                        <li><strong>To improve our services:</strong> To understand and analyse how you use our services, and to develop new products, services, features, and functionality.</li>
                        <li><strong>To communicate with you:</strong> To send you updates, notifications, and other information related to our services, and to respond to your comments, questions, and requests.</li>
                        <li><strong>For marketing and promotional purposes:</strong> To provide you with news, special offers, and other information about our services.</li>
                        <li><strong>To ensure security:</strong> To protect against and prevent fraud, unauthorised activities, and other potential threats.</li>
                    </ul>
                </section>
                <section>
                    <h2>3. How We Protect Your Information</h2>
                    <p>We implement a variety of security measures to maintain the safety of your personal information. These measures include encryption, access controls, and secure data storage.</p>
                </section>
                <section>
                    <h2>4. Your Choices</h2>
                    <p>You have certain choices regarding your information:</p>
                    <ul>
                        <li><strong>Access and Update:</strong> You can access and update your account information at any time.</li>
                        <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications from us by following the unsubscribe instructions in those communications.</li>
                        <li><strong>Cookies:</strong> You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent.</li>
                    </ul>
                </section>
                <section>
                    <h2>5. Changes to This Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the "Last updated" date.</p>
                </section>
                <section>
                    <h2>6. Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:sameyaiofficial@gmail.com">sameyaiofficial@gmail.com</a></p>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
