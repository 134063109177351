import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Blog.css';
import Header from '../components/Header'; 
import CallToAction from '../components/CallToAction'; 
import Footer from '../components/Footer';
import blogImage3 from '../assets/images/Blog 3_Cover_Image.jpg';
import blogImage2 from '../assets/images/Blog 2_Cover_Image.jpg';
import blogImage1 from '../assets/images/Blog 1_Cover_Image.jpg';

const Blog = () => {
    return (
        <div>
            <Helmet>
                <title>SameyAI - Blogs and Updates</title>
                <meta name="description" content="Stay updated with the latest news, blogs, and updates from SameyAI. Discover insights on AI-driven solutions, industry trends, and how Samey is transforming business operations." />
                <meta name="keywords" content="SameyAI, blogs, updates, AI solutions, legal technology, SME operations, AI-powered assistant, business automation" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header /> {/* Include the header component */}
            <div className="breadcrumbs">
                <a href="/">Home</a> / <span>Blogs And Updates</span>
            </div>
            <section className="blog-section">
                <div className="blog-header">
                    <span className="blog-tag">BLOGS AND UPDATES</span>
                    <h1>Updates from us to you</h1>
                </div>
                <div className="featured-article">
                    <div className="article-content">
                        <h2>Transforming Legal Practices using Samey</h2>
                        <p>The legal industry, often perceived as slow to adopt new technologies, is on the brink of a transformative shift. Imagine a world where tedious document management, endless case files, and the constant juggling of deadlines are handled effortlessly by an intelligent assistant.</p>
                        <a href="/blog/transforming-legal-practices-using-samey" className="learn-more">Learn More</a>
                    </div>
                    <img src={blogImage3} alt="Featured Article" />
                </div>
                <div className="latest-updates">
                    <h2>Latest Updates</h2>
                    <div className="article-grid">
                        <div className="article">
                            <img src={blogImage2} alt="Article" />
                            <h3>How Samey is Revolutionising SME Operations</h3>
                            <p>In today's fast-paced business environment, small and medium-sized enterprises (SMEs) face the challenge of managing vast amounts of data while maintaining efficiency. Samey, an AI-powered work assistant, offers a comprehensive solution to these challenges.</p>
                            <a href="/blog/how-samey-is-revolutionising-sme-operations" className="learn-more">Learn More</a>
                        </div>
                        <div className="article">
                            <img src={blogImage1} alt="Article" />
                            <h3>Introducing Samey: The Best AI-Powered Work Assistant for SMEs</h3>
                            <p>In the dynamic landscape of small and medium-sized enterprises (SMEs), efficiency and adaptability are paramount. SMEs often operate with limited resources, making it crucial to maximise productivity and streamline operations. This is where innovative solutions like Samey come into play.</p>
                            <a href="/blog/intoducing-samey-ai" className="learn-more">Learn More</a>
                        </div>
                        {/* Repeat for more articles */}
                    </div>
                </div>
            </section><br></br><br></br>
            <CallToAction /> 
            <Footer />
        </div>
    );
}

export default Blog;
