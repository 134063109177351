import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header'; 
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer'; 
import '../styles/Careers.css'; 
import teamImage from '../assets/images/Frame 187.png'; 
import icon1 from '../assets/images/flower-pot.png';
import icon2 from '../assets/images/analysis-text-link.png'
import icon3 from '../assets/images/agreement-01.png'
import icon4 from '../assets/images/ranking.png'
import icon5 from '../assets/images/no-meeting-room.png'
import icon6 from '../assets/images/ai-laptop.png'

const Careers = () => {
    const jobListingsRef = useRef(null);

    const scrollToJobListings = () => {
        if (jobListingsRef.current) {
            jobListingsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <Helmet>
                <title>SameyAI - Careers</title>
                <meta name="description" content="Join SameyAI and be part of a team that's transforming enterprise operations with AI. Explore exciting career opportunities and help businesses thrive in a rapidly changing world." />
                <meta name="keywords" content="SameyAI, careers, jobs, AI careers, work at SameyAI, job opportunities, AI jobs, technology careers" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="careers-container">
                <div className="careers-text">
                    <h1>Careers at Samey AI</h1>
                    <p>
                        At Samey AI, we're at the forefront of transforming enterprise operations with cutting-edge artificial intelligence solutions.
                        Our mission is to empower businesses with the tools they need to thrive in a rapidly changing world.
                        As a team, we are passionate about innovation, excellence, and creating meaningful impact.
                    </p>
                    <button className="view-positions-button" onClick={scrollToJobListings}>
                        View Open Positions
                    </button>
                </div>
                <img src={teamImage} alt="Team at Samey AI" className="team-image"/>
            </div>
            <div className="why-work-with-us">
                <h2>Why Work With Us?</h2>
                <div className="benefits-grid">
                    <div className="benefit">
                        <img src={icon1} alt="Icon 1" />
                        <h3>Innovative Environment</h3>
                        <p>Be a part of a dynamic and forward-thinking team that values creativity and encourages innovation.</p>
                    </div>
                    <div className="benefit">
                        <img src={icon2} alt="Icon 2" />
                        <h3>Growth Opportunities</h3>
                        <p>We invest in our team's growth through continuous learning and professional development.</p>
                    </div>
                    <div className="benefit">
                        <img src={icon3} alt="Icon 3" />
                        <h3>Collaborative Culture</h3>
                        <p>Work alongside industry experts in a collaborative and inclusive environment where your ideas are valued.</p>
                    </div>
                    <div className="benefit">
                        <img src={icon4} alt="Icon 4" />
                        <h3>Impactful Work</h3>
                        <p>Contribute to projects that make a real difference in the enterprise landscape, helping businesses succeed and grow.</p>
                    </div>
                    <div className="benefit">
                        <img src={icon5} alt="Icon 5" />
                        <h3>Flexible Work Arrangements</h3>
                        <p>We offer flexible work options to support a healthy work-life balance.</p>
                    </div>
                    <div className="benefit">
                        <img src={icon6} alt="Icon 6" />
                        <h3>Cutting-Edge Technology</h3>
                        <p>Engage with state-of-the-art AI technologies and tools, working on projects that push the boundaries of what's possible in the industry.</p>
                    </div>
                </div>
            </div>
            <div ref={jobListingsRef} className="explore-opportunities">
                <h2>Explore Opportunities</h2>
                <p>
                    We're looking for talented and motivated individuals to join us on our journey. Whether you're an AI expert, software developer, data scientist, or business strategist, we have exciting opportunities across various departments.
                </p>
                <div className="job-listings">
                    <div className="job">
                        <div className="job-details">
                            <h3>Application Development Engineer</h3>
                            <a href="#" className="job-location">Remote</a>
                            <p>We’re on the lookout for Application Development Engineer to join us.</p>
                        </div>
                        <a href="https://forms.gle/cwwKrwcBQv1NCCE1A" className="see-position-button-link">
                            <button className="see-position-button">Apply Now</button>
                        </a>
                    </div>
                    <div className="job">
                        <div className="job-details">
                            <h3>AI Developer</h3>
                            <a href="#" className="job-location">Remote</a>
                            <p>We’re on the lookout for AI Developer to join us.</p>
                        </div>
                        <a href="https://forms.gle/brpoNJ3hUaBMWtxP7" className="see-position-button-link">
                            <button className="see-position-button">Apply Now</button>
                        </a>
                    </div>
                    {/* Add more job listings here */}
                </div>
            </div>
            <CallToAction />
            <Footer />
        </div>
    );
}

export default Careers;
