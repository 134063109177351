import React from 'react';
import '../styles/BlogUpdates.css';
import blogImage3 from '../assets/images/Blog 3_Cover_Image.jpg';
import blogImage2 from '../assets/images/Blog 2_Cover_Image.jpg';
import blogImage1 from '../assets/images/Blog 1_Cover_Image.jpg';

const BlogUpdates = () => {
    return (
        <section className="blog-updates-section">
            <div className="blog-updates-header">
                <div className="blog-updates-tag">BLOG AND UPDATES</div>
                <h2>Updates from us to you</h2>
            </div>
            <div className="blogs">
                <div className="blog">
                    <img src={blogImage1} alt="Blog1" />
                    <h3>Introducing Samey: The Best AI-Powered Work Assistant for SMEs</h3>
                    <p>In the dynamic landscape of small and medium-sized enterprises (SMEs), efficiency and adaptability are paramount. SMEs often operate with limited resources, making it crucial to maximise productivity and streamline operations. This is where innovative solutions like Samey come into play.</p>
                    <a href="/blog/intoducing-samey-ai" className="learn-more-link">Learn More</a>
                </div>
                <div className="blog">
                    <img src={blogImage2} alt="Blog" />
                    <h3>How Samey is Revolutionising SME Operations</h3>
                    <p>In today's fast-paced business environment, small and medium-sized enterprises (SMEs) face the challenge of managing vast amounts of data while maintaining efficiency. Samey, an AI-powered work assistant, offers a comprehensive solution to these challenges.</p>
                    <a href="/blog/how-samey-is-revolutionising-sme-operations" className="learn-more-link">Learn More</a>
                </div>
                <div className="blog">
                    <img src={blogImage3} alt="Blog" />
                    <h3>Transforming Legal Practices using Samey</h3>
                    <p>The legal industry, often perceived as slow to adopt new technologies, is on the brink of a transformative shift. Imagine a world where tedious document management, endless case files, and the constant juggling of deadlines are handled effortlessly by an intelligent assistant.</p>
                    <a href="/blog/transforming-legal-practices-using-samey" className="learn-more-link">Learn More</a>
                </div>
            </div>
            <a href="/blog" className="see-all-button-link">
                <button className="see-all-button">See All Blog Posts</button>
            </a>
        </section>
    );
}

export default BlogUpdates;
