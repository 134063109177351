import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Healthcare.css';
import Header from '../components/Header';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import Icon1 from '../assets/images/note-03.png';
import Icon2 from '../assets/images/task-edit-01.png';
import Icon3 from '../assets/images/co-present.png';
import InfoImage1 from '../assets/images/Data Integration.png';  
import InfoImage2 from '../assets/images/Compliance and Reporting.png';  
import InfoImage3 from '../assets/images/Medical Documentation and Reporting.png';  

const Healthcare = () => {
    return (
        <>
            <Helmet>
                <title>SameyAI - Healthcare</title>
                <meta name="description" content="Empower your healthcare practice with AI-driven solutions from SameyAI. Explore patient data management, automated admin tasks, resource optimization, and compliance reporting tools." />
                <meta name="keywords" content="SameyAI, healthcare AI, health technology, patient data management, healthcare workflows, compliance reporting, medical documentation, data integration" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="healthcare-header">
                <div className="healthcare-header-content">
                    <span className="healthcare-tag">HEALTHCARE</span>
                    <h1>Empower Healthcare With AI-Powered Solutions</h1>
                </div>
            </div>
            <div className="healthcare-solutions-section">
                <h2>Solutions we provide</h2>
                <div className="solutions-grid">
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon1} alt="Managing Patient Data" />
                        </div>
                        <h3>Managing Patient Data</h3>
                        <p>Efficiently manages and organizes patient records and health data.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon2} alt="Automating Admin Tasks" />
                        </div>
                        <h3>Automating Admin Tasks</h3>
                        <p>Automates administrative tasks such as appointment scheduling and billing.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon3} alt="Resource Management and Optimization" />
                        </div>
                        <h3>Resource Management and Optimization</h3>
                        <p>Identify resource needs, streamline staffing schedules, and manage equipment and facility utilization.</p>
                    </div>
                </div>
            </div>
            <div className="information-retrieval-section">
                <div className="information-retrieval-content">
                    <div className="info-text">
                        <h2>Data Integration</h2>
                        <p>Integrates diverse health information systems for a cohesive data flow.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage1} alt="Data Integration" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="data-management-section">
                <div className="data-management-content">
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage2} alt="Compliance and Reporting" className="info-image" />
                        </div>
                    </div>
                    <div className="info-text">
                        <h2>Compliance and Reporting</h2>
                        <p>Ensures compliance with health regulations through automated reporting.</p>
                    </div>
                </div>
            </div>
            <div className="matter-information-section">
                <div className="matter-information-content">
                    <div className="info-text">
                        <h2>Medical Documentation and Reporting</h2>
                        <p>Automates medical documentation by transcribing notes, filling out forms, and generating reports. Reduce administrative burden and allow healthcare providers to focus more on patient care.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage3} alt="Medical Documentation and Reporting" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <CallToAction />
            <Footer />
        </>
    );
}

export default Healthcare;
