import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Consulting.css';
import Header from '../components/Header';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import Icon1 from '../assets/images/code-folder.png';
import Icon2 from '../assets/images/property-edit.png';
import Icon3 from '../assets/images/database-01.png';
import InfoImage1 from '../assets/images/Report Generation Image.png';  
import InfoImage2 from '../assets/images/Workflow Optimization.png';  
import InfoImage3 from '../assets/images/Centralised Requirements Management.png';  

const Consulting = () => {
    return (
        <>
            <Helmet>
                <title>SameyAI - Consulting</title>
                <meta name="description" content="Unlock the potential of consulting with AI-driven solutions from SameyAI. Explore client data integration, automated task management, proposal generation, and workflow optimization." />
                <meta name="keywords" content="SameyAI, consulting AI, consulting technology, client data integration, workflow automation, proposal generation, report generation, requirements management" />
                <meta name="author" content="SameyAI" />
                
                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="consulting-header">
                <div className="consulting-header-content">
                    <span className="consulting-tag">CONSULTING</span>
                    <h1>Unlock Consulting Potential With AI Brilliance</h1>
                </div>
            </div>
            <div className="consulting-solutions-section">
                <h2>Solutions we provide</h2>
                <div className="solutions-grid">
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon1} alt="Integrating Client Data" />
                        </div>
                        <h3>Integrating Client Data</h3>
                        <p>Integrates various data sources to provide a unified view of client information.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon2} alt="Automating Consulting Tasks" />
                        </div>
                        <h3>Automating Consulting Tasks</h3>
                        <p>Automates routine consulting tasks such as scheduling and data entry.</p>
                    </div>
                    <div className="solution-card">
                        <div className="icon">
                            <img src={Icon3} alt="Client Proposal Generation" />
                        </div>
                        <h3>Client Proposal Generation</h3>
                        <p>Automates the creation of client proposals by pulling in relevant data, case studies, and methodologies, ensuring that proposals are tailored and comprehensive.</p>
                    </div>
                </div>
            </div>
            <div className="information-retrieval-section">
                <div className="information-retrieval-content">
                    <div className="info-text">
                        <h2>Report Generation</h2>
                        <p>Automatically compiles and generates detailed client reports.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage1} alt="Report Generation" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="data-management-section">
                <div className="data-management-content">
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage2} alt="Workflow Optimisation" className="info-image" />
                        </div>
                    </div>
                    <div className="info-text">
                        <h2>Workflow Optimisation</h2>
                        <p>Enhances operational efficiency through AI-driven workflow optimisation.</p>
                    </div>
                </div>
            </div>
            <div className="matter-information-section">
                <div className="matter-information-content">
                    <div className="info-text">
                        <h2>Centralised Requirements Management</h2>
                        <p>Centralises all project requirements, ensuring that all stakeholders have access to the latest information, reducing project delays.</p>
                    </div>
                    <div className="info-image-wrapper">
                        <div className="orange-box">
                            <img src={InfoImage3} alt="Centralised Requirements Management" className="info-image" />
                        </div>
                    </div>
                </div>
            </div>
            <CallToAction />
            <Footer />
        </>
    );
}

export default Consulting;
