import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/CallToAction.css';

const CallToAction = () => {
    return (
        <section className="cta-section">
            <h2>Supercharge your Enterprise with AI</h2>
            <p>Scale your business with intelligent, seamless, and adaptive AI solutions</p>
            <div className="cta-buttons">
                <NavLink to="/contact">
                    <button className="cta-button">Contact Us</button>
                </NavLink>
            </div>
        </section>
    );
}

export default CallToAction;
