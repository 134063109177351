import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Footer.css';
import logo from '../assets/images/Logo.png';
import instagram from '../assets/images/instagram.png';
import xIcon from '../assets/images/x.png';
import linkedin from '../assets/images/linkedin-02.png';
import copyright from '../assets/images/copyright.png';
import sameyLogo from '../assets/images/samey-logo.png'; 

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-top">
                    <div className="footer-logo">
                        <NavLink to="/" exact>
                            <img src={logo} alt="Samey AI Logo" />
                        </NavLink>
                    </div>
                    <div className="footer-columns">
                        <div className="footer-column">
                            <h4>Features</h4>
                            <ul>
                                <li><a href="/connectors">Integrations</a></li>
                            </ul>
                        </div>
                        <div className="footer-column">
                            <h4>Solutions</h4>
                            <ul>
                                <li><a href="/legal">Legal</a></li>
                                <li><a href="/consulting">Consulting</a></li>
                                <li><a href="/healthcare">Healthcare</a></li>
                                <li><a href="/finance">Finance</a></li>
                            </ul>
                        </div>
                        <div className="footer-column">
                            <h4>Resources</h4>
                            <ul>
                                <li><a href="/blog">Blog</a></li>
                                <li><a href="/privacy">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div className="footer-column">
                            <h4>Company</h4>
                            <ul>
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/careers">Careers</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="footer-bottom">
                    <div className="footer-social">
                        <a href=" https://www.instagram.com/sameyaiofficial?igsh=OW9rNnZ5MmJjMTJm&utm_source=qr"><img src={instagram} alt="Instagram" /></a>
                        <a href="https://x.com/samey_ai"><img src={xIcon} alt="X" /></a>
                        <a href="https://www.linkedin.com/company/samey-ai/"><img src={linkedin} alt="LinkedIn" /></a>
                    </div>
                    <div className="footer-right">
                        <p>
                            <img src={copyright} alt="Copyright" className="icon" /> 
                            <img src={sameyLogo} alt="Samey AI Logo" className="samey-logo" />
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
