import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import '../styles/Contact.css';
import Header from '../components/Header'; 
import Footer from '../components/Footer'; 
import instagram from '../assets/images/instagram.png';
import xIcon from '../assets/images/x.png';
import facebook from '../assets/images/facebook-02.png';
import linkedin from '../assets/images/linkedin-02.png';

const Contact = () => {
    return (
        <div>
            <Helmet>
                <title>SameyAI - Contact Us</title>
                <meta name="description" content="Get in touch with SameyAI for licensing, billing, technical issues, feedback, or details about our Business plan. Reach out to our support team through chat, email, or social media." />
                <meta name="keywords" content="SameyAI, contact, support, customer service, business plan, feedback, technical support" />
                <meta name="author" content="SameyAI" />

                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-JY4NRM3G3R"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-JY4NRM3G3R');
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="contact-us-container">
                <h1>Let's Talk</h1>
                <p>Got a licensing, billing or technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p>
                <div className="contact-us-content">
                    <div className="contact-us-form">
                        <h2>Reach Out to Us</h2>
                        {/*<form onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                name="fullName" 
                                placeholder="Full Name" 
                                value={formData.fullName} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="email" 
                                name="workEmail" 
                                placeholder="Work Email" 
                                value={formData.workEmail} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="tel" 
                                name="phoneNumber" 
                                placeholder="Phone Number" 
                                value={formData.phoneNumber} 
                                onChange={handleChange} 
                                required 
                            />
                            <input 
                                type="text" 
                                name="company" 
                                placeholder="Company" 
                                value={formData.company} 
                                onChange={handleChange} 
                                required 
                            />
                            <select
                                name="industry"
                                value={formData.industry}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Select your industry</option>
                                <option value="Legal">Legal</option>
                                <option value="Consulting">Consulting</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Finance">Finance</option>
                                <option value="Other">Other</option>
                            </select>
                            <textarea 
                                name="message" 
                                placeholder="How can we help you?" 
                                value={formData.message} 
                                onChange={handleChange} 
                                required 
                            ></textarea>
                            <button type="submit">Send</button>
                        </form> */}
                        <NavLink to="https://forms.gle/JLUbvBoJVXvup4db8">
                            <button className="contact-button">Contact Us</button>
                        </NavLink>
                    </div>
                    <div className="contact-us-info">
                        <h2>Chat with us</h2>
                        <p>If you’d prefer to chat in real time with our support team, we’re online Monday to Friday whatever your time zone.</p>
                        <div className="social-icons">
                            <a href=" https://www.instagram.com/sameyaiofficial?igsh=OW9rNnZ5MmJjMTJm&utm_source=qr"><img src={instagram} alt="Instagram" /></a>
                            <a href="https://x.com/samey_ai"><img src={xIcon} alt="X" /></a>
                            {/*<a href="https://www.facebook.com"><img src={facebook} alt="Facebook" /></a>*/}
                            <a href="https://www.linkedin.com/company/samey-ai/"><img src={linkedin} alt="LinkedIn" /></a>
                        </div>
                        <h3>Would you prefer to email us?</h3>
                        <p>You can reach us at <a href="mailto:sameyaiofficial@gmail.com">sameyaiofficial@gmail.com</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;
